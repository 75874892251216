import 'jquery-mask-plugin';

function loadScript(params) {
  var script = document.createElement("script");

  script.async = true;
  script.src = params.src;
  script.onload = params.onLoad;
  document.body.appendChild(script);
}

function start(params) {
  function checkPhoneForm() {
    var isValid =
      $formPhone.get(0).offsetWidth === 0 || fields.phoneInput.isValid;
    $formBtn.attr("disabled", !isValid);
    $formBtn.css({
      opacity: isValid ? 1 : 0.5,
    });
  }

  var $formPhone = $(".form-phone");
  var $formBtn = $(".form-btn");

  var fields = {
    phoneInput: {
      $el: $(".phone-input"),
      isValid: false,
    },
    phoneSelect: {
      $el: $(".phone-prefix__select"),
    },
  };

  fields.phoneInput.$el.attr("type", "text");
  fields.phoneInput.$el.attr("inputmode", "tel");

  var errorEl = fields.phoneInput.$el
    .closest(".phone-prefix__wrap")
    .parent()
    .get(0)
    .querySelector(".formError.phone-error");

  fields.phoneSelect.$el.on("change", function (e) {
    var phone = params.phones.find(function (phone) {
      return phone.alpha2.toLowerCase() === e.target.value.toLowerCase();
    });

    if (phone) {
      var isGermanPhone = phone.alpha2 === 'DE'
      var germanMaskBehavior = function (val) {
        var baseMask = phone.phone_sample.replace(/\d/gi, "0");
        var tenDigitMask = phone.phone_sample.slice(0, -2).replace(/\d/gi, "0");
        var elevenDigitMask = phone.phone_sample.slice(0, -1).replace(/\d/gi, "0");

        if (val.length <= 10) {
          return tenDigitMask;
        }
        if (val.length <= 11) {
          return elevenDigitMask;
        }

        return baseMask;
      };
      var mask = isGermanPhone ?  germanMaskBehavior : phone.phone_sample.replace(/\d/gi, "0");

      fields.phoneInput.$el.attr("placeholder", phone.phone_sample);

      fields.phoneInput.$el.mask(mask, {
        onComplete() {
          fields.phoneInput.isValid = true;
          checkPhoneForm();
        },

        onInvalid() {
          console.log("invalid");
        },

        onKeyPress(val, e, field, options) {
          if (isGermanPhone) {
            field.mask(germanMaskBehavior.apply({}, arguments), options);
          }
          fields.phoneInput.isValid = false;
          checkPhoneForm();

          if (!errorEl) {
            errorEl = document.createElement("div");
            fields.phoneInput.$el
              .closest(".phone-prefix__wrap")
              .parent()
              .get(0)
              .appendChild(errorEl);
          }

          errorEl.className = "formError phone-error";

          var min = phone.phone_sample.replace(/ /gi, "").length;
          var estimate = min - fields.phoneInput.$el.cleanVal().length;

          if (isGermanPhone) {
            min = 10;
          }

          if (isGermanPhone && estimate > 2 && min - estimate > 1) {
            errorEl.innerHTML = params.translations.validation.min
                .replace("{min}", min)
                .replace("{length}", estimate);
          } else if (!isGermanPhone && estimate > 0 && min - estimate > 1) {
            errorEl.innerHTML = params.translations.validation.min
              .replace("{min}", min)
              .replace("{length}", estimate);
          } else {
            errorEl.innerHTML = "";
          }
        },
      });

      fields.phoneInput.isValid =
          isGermanPhone ?
              fields.phoneInput.$el.cleanVal().length >= 10 &&
              fields.phoneInput.$el.cleanVal().length <= 12 :
              fields.phoneInput.$el.masked(fields.phoneInput.$el.cleanVal()).length ===
              mask.length;

      checkPhoneForm();
    } else {
      fields.phoneInput.$el.unmask();
    }
  });

  fields.phoneSelect.$el.trigger("change");

  checkPhoneForm();
  $("body").on("click", checkPhoneForm);
}

export {
  loadScript, start
};
