import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0995\u09cb\u09a8\u0993\u0020\u09ae\u09bf\u09b2\u0020\u0996\u09c1\u0981\u099c\u09c7\u0020\u09aa\u09be\u0993\u09af\u09bc\u09be\u0020\u09af\u09be\u09af\u09bc\u0020\u09a8\u09bf",
        messages: {
            required: '\u09ab\u09bf\u09b2\u09cd\u09a1\u0997\u09c1\u09b2\u09cb\u0020\u09aa\u09c2\u09b0\u09a3\u0020\u0995\u09b0\u09c1\u09a8',
            phone: {
                incorrect: '\u09ab\u09cb\u09a8\u0020\u09a8\u09ae\u09cd\u09ac\u09b0\u0020\u09b8\u09a0\u09bf\u0995\u0020\u09a8\u09df'
            },
            email: {
                incorrect: '\u09ad\u09c1\u09b2\u0020\u09a0\u09bf\u0995\u09be\u09a8\u09be',
                symbolNotFound: '\u09a0\u09bf\u0995\u09be\u09a8\u09be\u09af\u09bc\u0020\u0985\u09ac\u09b6\u09cd\u09af\u0987\u0020\u0022\u0022\u0040\u0022\u0022\u0020\u099a\u09bf\u09b9\u09cd\u09a8\u0020\u09a5\u09be\u0995\u09a4\u09c7\u0020\u09b9\u09ac\u09c7\u0964\u0020\u0022\u0022\u0040\u0022\u0022\u0020\u099a\u09bf\u09b9\u09cd\u09a8\u099f\u09bf\u0020\u09a0\u09bf\u0995\u09be\u09a8\u09be\u09af\u09bc\u0020\u0985\u09a8\u09c1\u09aa\u09b8\u09cd\u09a5\u09bf\u09a4',
                addressIsNotFull: '\u201c\u0040\u201c\u0020\u099a\u09bf\u09b9\u09cd\u09a8\u09c7\u09b0\u0020\u09aa\u09b0\u09c7\u0020\u09a0\u09bf\u0995\u09be\u09a8\u09be\u09b0\u0020\u0985\u0982\u09b6\u099f\u09bf\u0020\u09aa\u09cd\u09b0\u09ac\u09c7\u09b6\u0020\u0995\u09b0\u09be\u09a8\u0964\u0020\u09a0\u09bf\u0995\u09be\u09a8\u09be\u0020\u09b8\u09ae\u09cd\u09aa\u09c2\u09b0\u09cd\u09a3\u0020\u09a8\u09af\u09bc'
            },
            password: {
                minLength: '\u0985\u09a8\u09c1\u09ae\u09cb\u09a6\u09bf\u09a4\u0020\u09a8\u09cd\u09af\u09c2\u09a8\u09a4\u09ae\u0020\u09b8\u0982\u0996\u09cd\u09af\u09be\u0020\u09ec\u0964\u0020\u098f\u0996\u09a8: %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u09aa\u09cd\u09b0\u09ac\u09c7\u09b6\u0020\u0995\u09b0\u09be\u0020\u09aa\u09be\u09b8\u0993\u09af\u09bc\u09be\u09b0\u09cd\u09a1\u0020\u09ae\u09c7\u09b2\u09c7\u0020\u09a8\u09be'
            },
            ofertaAgreement: {
                notChecked: '\u09ac\u09bf\u09a7\u09bf\u0997\u09c1\u09b2\u09bf\u09b0\u0020\u09b8\u09be\u09a5\u09c7\u0020\u0986\u09aa\u09a8\u09be\u09b0\u0020\u099a\u09c1\u0995\u09cd\u09a4\u09bf\u09b0\u0020\u09a8\u09bf\u09b6\u09cd\u099a\u09af\u09bc\u09a4\u09be\u0020\u09a6\u09bf\u09a8'
            },
        },

        options: {
            regType: "phone",
            bonusType: "sport",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});